"use client";

import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import BorderColoredInput from "@/components/styled/input/BorderColoredInput";
import { useAuthPopupContextSelector } from "@/features/Auth/AuthPopup/hook/useAuthPopupContext";
import { AuthEvent } from "@/features/Auth/AuthPopup/utils/constants";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import br from "@/styles/breakpoints.module.css";
import localKeyValueStore from "@/utils/LocalKeyValueStore";
import { TIME } from "@/utils/dateTimeUtils";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { STORAGE_KEYS } from "@knowt/syncing/constants";
import { LandingPageQuizletImportLink } from "@knowt/syncing/constants/storage";
import { useRouter } from "@bprogress/next";
import { useRef } from "react";
import { toast } from "react-hot-toast";
import CircularFilledIcon from "@/components/CircularButton/styled/CircularFilledIcon";
import { ArrowLeft, ArrowRight, Sparkle } from "lucide-react";
import { borderRadius } from "@/utils/borderRadius";
import { ASSETS_URL } from "@/config/deployConstants";
import Image from "next/image";
import LinkCard from "@/components/cards/LinkCard";
import LinkWrapper from "@/components/wrappers/Link";
import Box from "@mui/material/Box";
import { TOOL_URL } from "@knowt/syncing/utils/aiTools";
import { AICompletionType } from "@knowt/syncing/graphql/schema";

export const QuizletImportLinkInput = ({
    style,
}: {
    style?: React.CSSProperties;
}) => {
    const { user } = useCurrentUser();
    const openAuthPopup = useAuthPopupContextSelector(state => state.openAuthPopup);

    const router = useRouter();

    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleSubmit = async () => {
        const quizletUrl = inputRef.current?.value;
        if (!quizletUrl) {
            toast.error("Please enter a Quizlet link");
            return;
        }

        await localKeyValueStore.setWithExpiry(
            STORAGE_KEYS.LANDING_PAGE_QUIZLET_IMPORT_LINK,
            { quizletUrl } as LandingPageQuizletImportLink,
            TIME.HOUR
        );
        await localKeyValueStore.setWithExpiry(STORAGE_KEYS.SKIP_INTRO_POPUPS, true, TIME.MINUTE * 15);

        if (user) {
            router.push("/home");
        } else {
            openAuthPopup({
                event: AuthEvent.LANDING_IMPORT_QUIZLET,
                title: "Sign up for free to access your imported set",
                description:
                    "Now you’ll be able to study your Quizlet set with learn mode, matching, and more for free",
            });
        }
    };

    return (
        <FlexRowAlignCenter
            className={br.smDownColumn}
            style={{ width: "100%", maxWidth: "80rem", gap: spacing.MD, ...style }}>
            <BorderColoredInput
                ref={inputRef}
                sx={{
                    height: "5.6rem",
                    backgroundColor: themeColors.neutralWhite,
                    paddingLeft: spacing.MD,
                }}
                placeholder={"Just paste your Quizlet link here to bring it over"}
                onKeyDown={async event => {
                    if (event.key === "Enter") handleSubmit();
                }}
            />
            <CircularRectTextButton
                onClick={handleSubmit}
                sx={{
                    height: "5.6rem",
                    backgroundColor: "#50d2c2",
                    color: themeColors.primaryDark,
                    paddingInline: spacing.LG,
                    borderRadius: "4rem",
                    fontSize: "1.5rem",
                }}>
                Import from Quizlet
            </CircularRectTextButton>
        </FlexRowAlignCenter>
    );
};

const DnDCard = () => {
    return (
        <LinkWrapper href={"/ai-pdf-summarizer"}>
            <FlexColumnAlignJustifyCenter
                style={{
                    borderRadius: "3rem",
                    backgroundColor: themeColors.neutralWhite,
                    border: `3px dashed ${themeColors.ai}`,
                    height: "33rem",
                    width: "29rem",
                    flexShrink: 0,
                    color: themeColors.neutralBlack,
                    padding: "4.8rem 3rem",
                    textAlign: "center",
                    gap: spacing.MD,
                }}>
                <FlexColumnAlignJustifyCenter
                    style={{
                        backgroundColor: themeColors.background,
                        borderRadius: borderRadius.circular,
                        width: "6.5rem",
                        height: "6.5rem",
                    }}>
                    <Image src={`${ASSETS_URL}/images/upload-icon.svg`} alt={"upload"} width={40} height={30} />
                </FlexColumnAlignJustifyCenter>
                <span className="bodyBold2">Upload a file to get notes & flashcard instantly!</span>
                <CircularRectTextButton
                    component={"div"}
                    className="bodyBold2"
                    style={{
                        backgroundImage: themeColors.aiGradient,
                        color: themeColors.pureBlack,
                        padding: `${spacing.XS} ${spacing.LG}`,
                        borderRadius: borderRadius.circular,
                        gap: spacing.XS,
                        border: `1px solid ${themeColors.ai}`,
                    }}>
                    <Sparkle size={25} color={themeColors.ai} fill={themeColors.ai} />
                    {"Select files"}
                </CircularRectTextButton>
            </FlexColumnAlignJustifyCenter>
        </LinkWrapper>
    );
};

const LiveLectureCard = () => {
    return (
        <LinkWrapper href={"/ai-lecture-note-taker"}>
            <FlexColumnAlignJustifyCenter
                style={{
                    borderRadius: "3rem",
                    backgroundColor: themeColors.videoLight,
                    height: "33rem",
                    width: "29rem",
                    flexShrink: 0,
                    color: themeColors.neutralBlack,
                    padding: "4.8rem 3rem",
                    textAlign: "center",
                    gap: spacing.MD,
                }}>
                <Image src={`${ASSETS_URL}/images/red-mic-icon.svg`} alt={"upload"} width={65} height={65} />
                <FlexColumnAlignJustifyCenter>
                    <span className="bodyBold2">Live Lecture Note Taker</span>
                    <span className="secondaryText1">Instant notes and summaries from audio recordings.</span>
                </FlexColumnAlignJustifyCenter>
                <CircularRectTextButton
                    className="bodyBold2"
                    component={"div"}
                    style={{
                        backgroundColor: themeColors.pureBlack,
                        color: themeColors.pureWhite,
                        padding: `${spacing.XS} ${spacing.LG}`,
                        borderRadius: borderRadius.circular,
                        width: "fit-content",
                    }}>
                    {"Record Audio"}
                </CircularRectTextButton>
            </FlexColumnAlignJustifyCenter>
        </LinkWrapper>
    );
};

const InnerCardRectangleLink = ({ image, title, href }: { image: string; title: string; href: string }) => {
    return (
        <LinkWrapper href={href} style={{ width: "100%" }}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "4.2rem",
                    gap: spacing.XS,
                    backgroundColor: themeColors.neutralWhite,
                    padding: spacing.XS_2,
                    borderRadius: "1.2rem",
                    width: "100%",
                    "&:hover": {
                        border: `1px solid ${themeColors.neutralBlack}`,
                    },
                }}>
                <Image src={image} alt={"upload"} width={25} height={25} />
                <span className="secondaryTextBold1">{title}</span>
            </Box>
        </LinkWrapper>
    );
};

const AIStudyToolsSection = () => {
    const scrollRef = useRef<HTMLDivElement | null>(null);

    return (
        <FlexColumnAlignJustifyCenter
            style={{ maxWidth: "130rem", margin: "0 auto", textAlign: "center", width: "100%" }}>
            <FlexColumnAlignJustifyCenter style={{ maxWidth: "94rem", marginBottom: "6rem" }}>
                <h1 className="heading1" style={{ marginBottom: "1.2rem", maxInlineSize: "85rem", fontWeight: "800" }}>
                    #1 Free Quizlet Alternative with AI Study Tools
                </h1>
                <p className="bodyXl">Paste your Quizlet link below to bring it over in seconds!</p>
                <QuizletImportLinkInput style={{ marginTop: "4rem" }} />
            </FlexColumnAlignJustifyCenter>

            <span style={{ color: themeColors.neutral2, fontSize: "2rem", fontWeight: 700, marginBottom: "2.5rem" }}>
                Looking for something else?
            </span>

            <FlexRowAlignCenter style={{ position: "relative", width: "100%", maxWidth: "130rem" }}>
                <CircularFilledIcon
                    Icon={ArrowLeft}
                    onClick={() => {
                        if (scrollRef.current) {
                            scrollRef.current.scrollTo({ left: 0, behavior: "smooth" });
                        }
                    }}
                    sx={{
                        boxShadow: "0px 3px 35px 0px rgba(0, 0, 0, 0.15)",
                        position: "absolute",
                        left: "-1.5rem",
                    }}
                />
                <FlexRowAlignCenter
                    ref={scrollRef}
                    className="hide-scrollbar"
                    style={{ gap: "4rem", overflow: "auto hidden", justifyContent: "safe center" }}>
                    <DnDCard />
                    <LiveLectureCard />
                    <LinkCard
                        title={"AP Exam Hub"}
                        style={{ backgroundColor: themeColors.docsLight }}
                        href="/exams/ap"
                        bottomComponent={
                            <FlexColumnAlignJustifyCenter style={{ gap: spacing.XS_2, padding: spacing.MD }}>
                                <InnerCardRectangleLink
                                    image={`${ASSETS_URL}/images/response-room-icon.svg`}
                                    title={"Practice Test Room"}
                                    href={"/exams/AP/practice-test-room"}
                                />
                                <InnerCardRectangleLink
                                    image={`${ASSETS_URL}/images/test-icon.svg`}
                                    title={"Free-Response Room"}
                                    href={"/exams/AP/frq-room"}
                                />
                                <InnerCardRectangleLink
                                    image={`${ASSETS_URL}/images/calculator-icon.svg`}
                                    title={"AP Score Calculator"}
                                    href={"/exams/AP/score-calculator"}
                                />
                            </FlexColumnAlignJustifyCenter>
                        }
                    />
                    <LinkCard
                        title={"Teacher? Try  our AI Tools"}
                        style={{ backgroundColor: themeColors.flashcardsLight }}
                        href="/free-ai-tools-for-teachers"
                        bottomComponent={
                            <FlexColumnAlignJustifyCenter
                                style={{ gap: spacing.XS, padding: `${spacing.XS_2} ${spacing.MD} ${spacing.MD}` }}>
                                <InnerCardRectangleLink
                                    image={`${ASSETS_URL}/images/sparkle-circle-icon.svg`}
                                    title={"Writing feedback"}
                                    href={`/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.WRITING_FEEDBACK]}`}
                                />
                                <InnerCardRectangleLink
                                    image={`${ASSETS_URL}/images/sparkle-circle-icon.svg`}
                                    title={"Text Proofreader"}
                                    href={`/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.TEXT_PROOFREADER]}`}
                                />
                                <InnerCardRectangleLink
                                    image={`${ASSETS_URL}/images/sparkle-circle-icon.svg`}
                                    title={"Rubric Generator"}
                                    href={`/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.RUBRIC_GENERATOR]}`}
                                />
                                <InnerCardRectangleLink
                                    image={`${ASSETS_URL}/images/sparkle-circle-icon.svg`}
                                    title={"Reading Passage"}
                                    href={`/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.READING_TEXT]}`}
                                />
                                <LinkWrapper href={"/free-ai-tools-for-teachers"}>
                                    <span className="secondaryTextBold1" style={{ color: themeColors.pptMed }}>
                                        See all
                                    </span>
                                </LinkWrapper>
                            </FlexColumnAlignJustifyCenter>
                        }
                    />
                    <LinkCard
                        title={"Write Notes with AI"}
                        href={"/ai-notes"}
                        style={{ backgroundColor: themeColors.docsLight }}
                        bottomComponent={
                            <Image
                                src={`${ASSETS_URL}/images/civil-war-screenshot.png`}
                                alt={"upload"}
                                width={249}
                                height={255}
                                style={{
                                    marginTop: spacing.XS_2,
                                    width: "80%",
                                    height: "80%",
                                    marginLeft: "auto",
                                }}
                            />
                        }
                    />
                    <LinkCard
                        title={"Snap & Solve"}
                        href={"/mobile"}
                        style={{ backgroundColor: themeColors.pptLight }}
                        bottomComponent={
                            <Image
                                src={`${ASSETS_URL}/images/scan-screenshot-mobile.png`}
                                alt={"upload"}
                                width={279}
                                height={255}
                                style={{
                                    marginTop: spacing.XS_2,
                                    height: "80%",
                                    marginInline: "auto",
                                }}
                            />
                        }
                    />
                </FlexRowAlignCenter>
                <CircularFilledIcon
                    Icon={ArrowRight}
                    onClick={() => {
                        if (scrollRef.current) {
                            scrollRef.current.scrollTo({ left: scrollRef.current.scrollWidth, behavior: "smooth" });
                        }
                    }}
                    sx={{
                        boxShadow: "0px 3px 35px 0px rgba(0, 0, 0, 0.15)",
                        position: "absolute",
                        right: "-1.5rem",
                    }}
                />
            </FlexRowAlignCenter>
        </FlexColumnAlignJustifyCenter>
    );
};

export default AIStudyToolsSection;
